<template>
  <section class="is-primary is-fullheight login-page">
    <div class="login-background">
      <div class="columns is-centered">
        <div class="column"></div>
        <div class="column is-one-third">
          <div class="card card-login-form">
            <div class="card-box">
              <header class="card-header">
                  <div class="columns row-title">
                    <div class="column column-logo is-full is-size-1">
                      <img width="154px" class="logo" src="../../assets/svgs/Logo/logo_color.svg" alt="">
                    </div>
                  </div>
              </header>
              <div class="card-content">
                <div class="content" @keyup.enter.stop="login()">
                  <div class="content-title">
                    <span>{{$t('login.content_title')}}</span>
                  </div>
                  <div class="field mt-5">
                    <div class="control has-icons-left">
                      <input type="text" v-model="employee_id" id="login-email"
                             :placeholder="$t('login.input_field.employee_id.placeholder')"
                             :class="{'is-error' : (!$v.employee_id.required && $v.$dirty)|| (error_server.employee_id && error_server.employee_id.length)}"
                             class="input is-large">
                      <span class="icon is-medium is-left">
                      <img class="icon-input-user" src="../../assets/svgs/Icon/account.svg" alt="">
                    </span>
                    </div>
                    <span class="error pt-1" v-if="error_server.employee_id && error_server.employee_id.length">
                    {{ error_server.employee_id[0] }}
                   </span>
                    <template v-else-if="$v.$dirty">
                    <span class="error pt-1"
                          v-if="!$v.employee_id.required"> {{ $t('login.messages.employee_id_required') }}</span>
                      <span
                          class="error pt-1"
                          v-if="!$v.employee_id.maxLength"> {{ $t('login.messages.employee_id_maxlength') }}</span>
                      <span
                          class="error pt-1"
                          v-if="!$v.employee_id.halfWidth"> {{ $t('login.messages.employee_id_halfWidth') }}</span>
                    </template>
                  </div>
                  <div class="field password-box">
                    <div class="control has-icons-left">
                      <input type="password" id="login-password"
                             :class="{'is-error' : (!$v.password.required && $v.$dirty) || (error_server.password && error_server.password.length)}"
                             v-model="password"
                             :placeholder="$t('login.input_field.password.placeholder')"
                             class="input is-large">
                      <span class="icon is-medium is-left">
                      <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                    </span>
                    </div>
                    <span class="error pt-1" v-if="error_server.password && error_server.password.length">
                    {{ error_server.password[0] }}
                  </span>
                    <template v-else-if="$v.$dirty">
                  <span class="error pt-1"
                        v-if="!$v.password.required">{{ $t('login.messages.password_required') }}</span>
                      <span class="error pt-1"
                            v-if="!$v.password.maxLength"> {{ $t('login.messages.password_maxlength') }}</span>
                    </template>

                  </div>
                  <div class="field has-text-centered button-box">
                    <button @click="login()" class="button btn-login is-align-items-flex-end" id="btn-login">
                      {{$t('buttons.login')}}
                    </button>
                  </div>
                  <div class="field has-text-centered forgot-password">
                    <router-link :to="{name: 'forgotPassword'}" class="forgot-password-link">
                      {{$t('buttons.forgot_password')}}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>

<script>
  import {mapGetters} from "vuex";
  import {required, maxLength} from "vuelidate/lib/validators";
  import {halfWidth} from "@/helpers/utils";
  import {ACTION_LOGIN} from "@/stores/auth/actions";

  export default {
    name: "Login",
    data() {
      return {
        employee_id: "",
        password: "",
        submitted: false,
        error_server: {},
        submitAvailable: true,
      };
    },
    methods: {
      async login() {
        if (this.submitAvailable) {
          this.error_server = {}
          this.message_error = " "
          this.submitted = true;
          this.$v.$touch();

          // stop here if form is invalid
          if (this.$v.$invalid) {
            return;
          }
            this.submitAvailable = false
            await this.$store.dispatch(ACTION_LOGIN, {
              employee_id: this.employee_id,
              password: this.password,
            }).then(() => {
              this.submitAvailable = true
            }, error => {
              this.submitAvailable = true
              this.error_server = error.data ? error.data.errors : ''
              if (this.error_server && this.error_server.unauthenticate) {
                this.$toast.error(error.data.errors.unauthenticate)
              }
            })
        }
      },
    },
    computed: {
      ...mapGetters(["isAuthenticated", "authUser"]),
    },
    validations: {
      employee_id: {
        required,
        maxLength: maxLength(20),
        halfWidth: (val) => {
          return halfWidth(val)
        },
      },
      password: {
        required,
        maxLength: maxLength(16),
      },
    },
    watch: {
      isAuthenticated(value) {
        if (value) {
          if(this.authUser.is_first_login) {
            this.$router.push({name: "ChangePassword"});
          }else {
            this.$router.push({name: "SearchDocuments"});
          }
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../assets/scss/login.scss";
</style>
